export const appColors: any = {
  active: 'light-5',
  placeholder: 'dark-1',
  error: '#D8222A',
  brand: '#1A619E',
  greenLeft: 'brand',
  greenRight: 'brand',
  lightGreen: 'brand',
  headText: '#819DAF',
  inputLabel: '#2B2C2B',
  white: '#ffffff',
  darkGray: '#8A8B8A',
  borderGreen: 'brand',
  menuBg: '#343434',
  menuText: '#B4B5B4',
  searchBar: '#4D4D4D',
  searchBarText: '#B4B5B4',
  pageHead: '#B4B5B4',
  textColor: '#818284',
  textSecondary: '#E7E7E8',
  orange: '#F1AA47',
  statusSuccess: '#3FCE2F',
};
