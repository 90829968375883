import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import { setLoaders, setPopupInfo } from '../../../data.action';
import {
  DELETE_CALENDAR_OUTLOOK,
  RETRIEVE_CALENDARS_OUTLOOK,
} from '../../../data.const';

const deleteCalendarOutlookEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DELETE_CALENDAR_OUTLOOK),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${DELETE_CALENDAR_OUTLOOK}]`, p)),
    withLatestFrom(state$),
    switchMap(([id, _state]) => {
      return concat(
        of(setLoaders({ calendarsLoader: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/calendars/${id}`,
              method: httpMethods.DELETE,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(
                `[epic ${DELETE_CALENDAR_OUTLOOK}] Calendar deleted, also ${p} events has been removed from database`,
                p
              )
            ),
            map((_p) => {
              return {
                type: RETRIEVE_CALENDARS_OUTLOOK,
                payload: null,
              };
            }),
            catchError((err) => {
              console.error(`[epic ${DELETE_CALENDAR_OUTLOOK}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ calendarsLoader: false }))
      );
    })
  );

export default deleteCalendarOutlookEpic;
