import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { SAVE_TO_CLOUD } from '../../data.const';
import {
  setCurrentCloudDocumentAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { getUser } from '../../../auth';
import { rootConfig } from '../../../../../config/root-config';

const saveToCloudEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SAVE_TO_CLOUD),
    map(({ payload }: any) => payload),
    withLatestFrom(state$),
    switchMap(([body, state]) => {
      const user = getUser(state);
      const { id: UserId } = user;

      const { data, autosave } = body;

      const formData = data as FormData;
      formData.append('userId', UserId);

      return concat(
        of(
          setLoaders({
            documentoLoader: !autosave,
            documentoAutoSaveLoader: autosave,
          })
        ),
        defer(() =>
          from(
            request({
              path: `SaveToCloud`,
              method: httpMethods.POST,
              body: formData,
              rootApi: rootConfig.documentEditorEndpoint,
              formData: true,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return p;
            }),
            tap((p) => console.log(`[epic ${SAVE_TO_CLOUD}]`, p)),
            switchMap((p) =>
              concat(
                of(setCurrentCloudDocumentAction(p)),
                of(
                  setPopupInfo({
                    opened: true,
                    message: 'Documento salvato con successo!',
                    type: 'GOOD',
                    timer: 3000,
                  })
                )
              )
            ),
            catchError((err) => {
              console.error(`[epic ${SAVE_TO_CLOUD}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  // eslint-disable-next-line no-nested-ternary
                  message: err.message.includes(
                    'The resource you are attempting to access is locked'
                  )
                    ? 'Un altro utente sta modificato il documento, riprova più tardi.'
                    : 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(
          setLoaders({ documentoLoader: false, documentoAutoSaveLoader: false })
        )
      );
    })
  );

export default saveToCloudEpic;
