import { combineReducers } from 'redux';
import {
  reducer as authReducer,
  STATE_KEY as AUTH_STATE_KEY,
} from './modules/auth';

import {
  reducer as dataReducer,
  STATE_KEY as DATA_STATE_KEY,
} from './modules/data';

export default combineReducers({
  [AUTH_STATE_KEY]: authReducer,
  [DATA_STATE_KEY]: dataReducer,
});
