import { ofType } from 'redux-observable';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import * as querystring from 'querystring';
import {
  getProjectsDueDiligenceSuccess,
  GET_PROJECTS_DUE_DILIGENCE,
  setLoaders,
} from '../../index';
import { getProjectsDueDiligenceList } from '../../../../../services/http/progetti.http-service';

import { noPratiche } from '../../../../../config/customizations';

const epic = (action$: any) =>
  action$.pipe(
    ofType(GET_PROJECTS_DUE_DILIGENCE),
    map(() => `?${querystring.stringify({ page: 1, pagesize: 100 })}`),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        from(getProjectsDueDiligenceList(payload)).pipe(
          map((p: any) => ({
            ...p,
            results: null,
            data: [
              {
                id: 'null',
                title: !noPratiche() ? 'Nessuna pratica' : 'Nessun progetto',
              },
              ...(p.results?.map((el: any) => {
                const title = el.name;
                return {
                  ...el,
                  title,
                };
              }) || []),
            ],
          })),
          switchMap((data) => of(getProjectsDueDiligenceSuccess(data))),
          catchError(() => EMPTY)
        ),
        of(setLoaders({ documents: false })).pipe(delay(300))
      )
    )
  );

export default epic;
