import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer } from 'rxjs';
import {
  httpMethods,
  singleFileUpload,
} from '../../../../../services/http-service';
import { SEND_DOCUMENTO } from '../../data.const';
import { setLoaders, setPopupInfo } from '../../data.action';

const upsertNotificaEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SEND_DOCUMENTO),
    map(({ payload }: any) => payload),
    tap((p) => console.log(`[epic ${SEND_DOCUMENTO}]`, p)),
    withLatestFrom(state$),
    switchMap(([body]) => {
      return concat(
        of(setLoaders({ documentoLoader: true })),
        defer(() =>
          from(
            singleFileUpload({
              path: `documento/send`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }

              if (!p?.data?.success) {
                throw new Error('Non è stato possibile inviare il documento');
              }

              return p?.data?.success;
            }),
            tap((p) => console.log(`[epic ${SEND_DOCUMENTO}]`, p)),
            switchMap((data) =>
              concat(
                of(
                  setPopupInfo({
                    opened: true,
                    message: 'Documento inviato con successo!',
                    type: 'GOOD',
                    timer: 3000,
                  })
                )
              )
            ),
            catchError((err) => {
              console.error(`[epic ${SEND_DOCUMENTO}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message || 'Si è verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ documentoLoader: false }))
      );
    })
  );

export default upsertNotificaEpic;
