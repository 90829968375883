import * as React from 'react';
import { Box, Image } from 'grommet';
import { useCallback } from 'react';
import { navigate } from 'gatsby';
import { rootConfig } from '../../config/root-config';
import LogoNomox from '../../images/nomox-def.png';

export const AppLogo = ({}: any) => {
  const click = useCallback(() => {
    navigate(`${rootConfig.app_path}/gestionale/home`);
  }, []);

  return (
    <Box
      id="logo"
      background="linear-gradient(90deg, #1A53A7 0%, #1A619E 100%)"
      width="300px"
      height="100%"
      justify="end"
      align="center"
      pad={{ bottom: '5px' }}
      margin={{ right: 'medium' }}
      focusIndicator={false}
      onClick={click}
    >
      <Box width="100px">
        <Image fit="contain" src={LogoNomox} style={{ maxHeight: 31 }} />
      </Box>
    </Box>
  );
};
