/* eslint-disable dot-notation */
import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';
import { rootEpic } from './root-epic';

const epicMiddleware = createEpicMiddleware();
const composeEnhancers =
  typeof window !== 'undefined' &&
  ((window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) &&
  process.env.NODE_ENV !== 'production'
    ? ((window as any)[
        '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'
      ] as typeof compose)
    : compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, epicMiddleware))
);

epicMiddleware.run(rootEpic);

export default { store };
