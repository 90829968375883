import * as querystring from 'querystring';
import { EMPTY, concat, from, of, timer } from 'rxjs';
import { ofType } from 'redux-observable';
import moment from 'moment';
import {
  catchError,
  delay,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { GET_DOCUMENTS, POLL_DOCUMENTS_STATUS } from '../../data.const';
import {
  getDocumentStatus,
  getDocumentsList,
} from '../../../../../services/http/documents.http-service';
import {
  getDocumentsSuccess,
  pollDocumentStatus,
  setLoaders,
} from '../../data.action';
import {
  selectDocumenti,
  selectFilters,
  selectSearchDocumentsText,
} from '../../index';

const filler = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

const epic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(GET_DOCUMENTS),
    map(({ payload }: any) => payload),
    withLatestFrom(state$),
    map(([{ page, pageSize, sortBy }, state]) => {
      const filters = selectFilters(state);
      const searchText = selectSearchDocumentsText(state);
      let tipologieFilter = '';
      let progettiFilter = '';
      let documentsSearch = '';
      let isSearch = false;

      if (filters?.tipologie) {
        tipologieFilter = Object.entries(filters.tipologie)
          .filter(([, value]) => value)
          .map(([key]) => key)
          .join('&filterBy=category:');
        if (tipologieFilter?.length > 0) {
          tipologieFilter = `&filterBy=category:${tipologieFilter}`;
        }
      }
      if (filters?.progetti) {
        progettiFilter = Object.entries(filters.progetti)
          .filter(([, value]) => value)
          .map(([key]) => key)
          .join('&filterBy=tag:');
        if (progettiFilter?.length > 0) {
          progettiFilter = `&filterBy=tag:${progettiFilter}`;
        }
      }

      // in case the user used the search box
      if (searchText) {
        isSearch = true;
        documentsSearch = `&q=${searchText}`;
      }

      const qsPayload: any = {
        page,
        pageSize,
      };
      if (sortBy) {
        qsPayload.sortBy = sortBy;
      } else {
        qsPayload.sortBy = 'create.desc';
      }
      const qs = `?${querystring.stringify(
        qsPayload
      )}${documentsSearch}${tipologieFilter}${progettiFilter}&filterRule=or`;
      // console.log('[queryString]', qs);
      return { qs, isSearch };
    }),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        timer(0, 5000).pipe(
          takeUntil(action$.pipe(ofType(POLL_DOCUMENTS_STATUS))),
          switchMap(() =>
            from(getDocumentsList(payload.qs, payload.isSearch)).pipe(
              withLatestFrom(state$),
              switchMap(([data, state]: any) =>
                from(
                  Promise.all(
                    data?.results?.map(async (el: any, index: number) => {
                      let status = null;
                      const prevDocuments = selectDocumenti(state);
                      const prevDocument = prevDocuments?.results?.find(
                        (el2: any) => el2.id === el.id
                      );
                      status = prevDocument?.status || null;
                      if (!status || status?.classification?.percentage < 100) {
                        const docStatus = await getDocumentStatus(el.id);
                        status = docStatus?.reports;
                      }
                      return {
                        ...el,
                        status,
                        created: moment
                          .utc(el.created)
                          .local()
                          .format('DD-MM-YYYY HH:mm:ss'),
                        updated: el.updated
                          ? moment
                              .utc(el.updated)
                              .local()
                              .format('DD-MM-YYYY HH:mm:ss')
                          : 'Nessuna modifica',
                      };
                    })
                  )
                ).pipe(
                  map((results: any) => ({
                    ...data,
                    results: [...results, ...filler],
                  }))
                )
              ),
              switchMap((data: any) => {
                const conditionalActions: any = [];
                if (
                  !data?.results
                    ?.filter((el: any) => el.id)
                    .find(
                      (el: any) => el.status?.classification?.percentage < 100
                    )
                ) {
                  conditionalActions.push(of(pollDocumentStatus()));
                }
                return concat(
                  ...conditionalActions,
                  of(getDocumentsSuccess(data)),
                  of(setLoaders({ documents: false })).pipe(delay(300))
                );
              }),
              catchError(() => EMPTY)
            )
          )
        ),
        of(setLoaders({ documents: false })).pipe(delay(300))
      )
    )
  );

export default epic;
