import * as React from 'react';
import { Box, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import './style.css';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { useCallback, useEffect, useState } from 'react';
import { setSearchAction } from '../../state/modules/data';
import { appColors } from '../../utils/colors';

const StyledTextInput = styled<typeof TextInput | any>(TextInput)`
  ${({ open }) => (open ? '' : 'max-width: 0;')}
  color: var(--searchBar);
  color: ${() => appColors.searchBar};
  position: relative;

  ::placeholder {
    color: var(--searchBar);
    color: ${() => appColors.searchBar};
  }
`;

const StyledTextContainer = styled<typeof Box | any>(Box)`
  border-radius: 8px;
  transition: width 200ms ease-out;
  background-color: ${({ open }) => (open ? '#ffffff' : 'transparent')};
  width: ${({ open }) => (open ? '100%' : '0')};
  position: absolute;
  right: 0;
  height: 95%;
`;

const StyledSearchIconBox = styled<typeof Box | any>(Box)`
  transition: all 200ms ease-out;
  border-radius: ${({ open }) => (open ? '100%' : '8px')};
  width: ${({ open }) => (open ? '30px' : '45px')};
  height: 30px;
  position: absolute;
  right: 2px;
`;

const StyledContainer = styled<typeof Box | any>(Box)`
  border-radius: 8px;
  background-color: transparent;
  position: relative;
`;

const Component = ({ setSearch }: any) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lazySearch = useCallback(debounce(setSearch, 500), [setSearch]);
  const location = useLocation();
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [searchOpened, set_searchOpened] = useState<boolean>(false);

  useEffect(() => {
    setSearch({ target: { value: '' } });
    setCurrentSearch('');
  }, [setSearch, location]);

  return (
    <StyledContainer
      width="250px"
      height="70%"
      justify="between"
      align="center"
      pad={{ left: 'small', right: '2px' }}
      direction="row"
      margin={{ left: 'xsmall', bottom: '4px' }}
      gap="small"
      open={searchOpened}
    >
      <StyledTextContainer
        open={searchOpened}
        gap="small"
        align="center"
        justify="between"
        pad={{ left: 'small', right: '2px' }}
        direction="row"
      >
        <StyledTextInput
          open={searchOpened}
          placeholder="Cerca qualcosa..."
          size="small"
          value={currentSearch}
          className="search-input"
          plain="full"
          focusIndicator={false}
          onChange={(e: any) => {
            setCurrentSearch(e?.target?.value ?? '');
            lazySearch(e);
          }}
        />
      </StyledTextContainer>
      <StyledSearchIconBox
        background="brand"
        justify="center"
        align="center"
        focusIndicator={false}
        onClick={() => set_searchOpened((p) => !p)}
        open={searchOpened}
      >
        <Search color="white" />
      </StyledSearchIconBox>
    </StyledContainer>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  setSearch: (payload: any) =>
    dispatch(setSearchAction(payload?.target?.value)),
});

export const MenuSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
