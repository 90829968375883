import React, { useCallback, useEffect, useState } from 'react';
import { Box, Text } from 'grommet';
import { navigate, useLocation } from '@reach/router';
import './style.css';

export const MenuButton = ({ label, action, to, activePage }: any) => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(location?.pathname?.startsWith(`${activePage}`) || false);
  }, [activePage, location.pathname]);

  const click = useCallback(() => {
    if (action) {
      action();
    } else {
      navigate(to);
    }
  }, [action, to]);

  return (
    <Box
      justify="end"
      pad={{ bottom: '8px', horizontal: 'medium' }}
      direction="row"
      gap="small"
      align="end"
    >
      <Box
        onClick={click}
        focusIndicator={false}
        className={(!active && 'menu-button') || ''}
      >
        <Text
          className="button-text"
          color={active ? 'brand' : 'menuText'}
          size="small"
          weight="bold"
        >
          {label}
        </Text>
      </Box>
    </Box>
  );
};
