import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { css, keyframes } from 'styled-components';
import { SortableIcon } from './SortableIcon';

export const menuAnimation = keyframes`
  0% {
    opacity: 0.0;
    transform: scaleY(0);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
`;

export const theme = deepMerge(grommet, {
  global: {
    colors: {
      active: 'light-5',
      placeholder: 'dark-1',
      error: '#D8222A',
      brand: '#1A619E',
      brand2: '#1C619E',
      greenLeft: 'brand',
      greenRight: 'brand',
      lightGreen: 'brand',
      headText: '#819DAF',
      headText2: '#9FD2F2',
      lightest: '#C8D8E6',
      inputLabel: '#2B2C2B',
      white: '#ffffff',
      darkGray: '#8A8B8A',
      borderGreen: 'brand',
      menuBg: '#343434',
      menuText: '#B4B5B4',
      searchBar: '#4D4D4D',
      searchBarText: '#B4B5B4',
      pageHead: '#B4B5B4',
      textColor: '#818284',
      textSecondary: '#E7E7E8',
      orange: '#F1AA47',
      statusSuccess: '#3FCE2F',
      yellowOne: '#FCEC14',
      selectedRow: '#C8D7E7',
    },
    elevation: {
      light: {
        small: '0 0 1px 0 rgba(0, 0, 0, 0.40), 0 1px 2px 0 rgba(0,0,0,0.40)',
        medium: '0 0 2px 0 rgba(0,0,0,0.40), 0 2px 4px 0 rgba(0,0,0,0.40)',
        large: '0 0 1px 0 rgba(0,0,0,0.40), 0 4px 8px 0 rgba(0,0,0,0.40)',
        xlarge: '0 0 1px 0 rgba(0,0,0,0.40), 0 8px 16px 0 rgba(0,0,0,0.40)',
      },
    },
    font: {
      size: '16px',
      height: '20px',
    },
    input: {
      weight: 400,
    },
    size: {
      avatar: '36px',
      sidebar: '60px',
    },
    drop: {
      extend: css`
        border-radius: 8px;
        min-width: 168px;
        animation: ${menuAnimation} 100ms ease-in-out forwards;

        /* button div {
          padding: 8px 16px;
        } */

        div div div button:hover {
          ${(props) => {
            const {
              theme: { dark },
            } = props;

            return dark ? 'background-color: --var(headText);' : '';
          }}
        }

        div div div:nth-of-type(1) button div {
          /* padding-top: 12px; */
        }

        div div div:nth-last-of-type(1) button div {
          /* padding-bottom: 12px; */
        }
      `,
    },
  },
  icon: {
    size: {
      medium: '18px',
    },
  },
  paragraph: {
    medium: {
      size: '16px',
      height: '20px',
    },
    large: {
      size: '20px',
      height: '24px',
    },
  },
  spinner: {
    size: {
      xsmall: '12px',
    },
  },
  accordion: {
    border: {
      side: 'bottom',
      size: '0px',
    },
    panel: {
      border: {
        style: 'hidden',
      },
    },
  },
  text: {
    xsmall: {
      size: '14px',
    },
    small: {
      size: '16px',
    },
    medium: {
      size: '18px',
    },
    large: {
      size: '22px',
    },
  },
  button: {
    color: 'brand',
    primary: {
      color: 'white',
    },
    disabled: {
      opacity: 0.8,
    },
  },
  textInput: {
    disabled: {
      opacity: 0.8,
    },
  },
  dataTable: {
    header: {
      font: {
        size: 'small',
      },
      color: 'text-strong',
      extend: ({ column, sort, sortable }: any) => `
          ${
            sortable &&
            sort &&
            sort.property !== column &&
            `
              :hover {
                svg {
                  opacity: 100%;
                }
              }
            `
          }
         `,
    },
    icons: {
      sortable: SortableIcon,
    },
  },
  layer: {
    border: {
      radius: 'medium',
      intelligentRounding: true,
    },
  },
  select: {
    options: { text: { size: 'small' } },
    clear: { text: { size: 'small' } },
  },
  radioButton: {
    size: '12px',
    container: {
      extend: `align-items: start;`,
    },
  },
});
