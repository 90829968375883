import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer, debounceTime } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import { RETRIEVE_CALENDARS_OUTLOOK } from '../../../data.const';
import { setLoaders, setPopupInfo, storeCalendars } from '../../../data.action';

const retrieveCalendarsOutlookEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_CALENDARS_OUTLOOK),
    debounceTime(20),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_CALENDARS_OUTLOOK}]`, p)),
    withLatestFrom(state$),
    switchMap(([_state]) => {
      return concat(
        of(setLoaders({ calendarsLoader: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/calendars`,
              method: httpMethods.GET,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(
                `[epic ${RETRIEVE_CALENDARS_OUTLOOK}] Retrieved calendars:`,
                p
              )
            ),
            switchMap((data) => of(storeCalendars(data))),
            catchError((err) => {
              console.error(`[epic ${RETRIEVE_CALENDARS_OUTLOOK}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ calendarsLoader: false }))
      );
    })
  );

export default retrieveCalendarsOutlookEpic;
