import * as React from 'react';
import { Box } from 'grommet';
import './style.css';
import styled from 'styled-components';
import { AppLogo } from '../app-logo/app-logo';
import { MenuUser } from '../menu-user/menu-user';
import { MenuSearch } from '../menu-search/menu-search';
import { MenuButton } from '../menu-button/menu-button';
import { MenuSeparator } from '../menu-separator/menu-separator';
import { rootConfig } from '../../config/root-config';
import { hasFatturazioneV2, noOnorari } from '../../config/customizations';

const StyledBox = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
`;

export const AppHeader = ({
  isLoggedIn,
  actions,
  user,
  account,
  isCompleta,
}: any) => {
  const { pct_enabled } = account ?? {};
  return (
    <StyledBox
      tag="header"
      background={isLoggedIn || isCompleta ? 'menuBg' : 'white'}
      border={[
        {
          color: 'white',
          size: '0px',
          style: 'solid',
          side: 'bottom',
        },
      ]}
    >
      <Box
        flex={false}
        direction="row"
        align="center"
        justify="between"
        responsive={false}
        height="50px"
      >
        {!!isLoggedIn && (
          // LOGGED IN MENU
          <>
            <Box
              id="left"
              pad="none"
              direction="row"
              height="100%"
              width="50%"
              justify="start"
              align="end"
            >
              <AppLogo />

              <MenuButton
                label="GESTIONALE"
                activePage={`${rootConfig.app_path}/gestionale`}
                to={`${rootConfig.app_path}/gestionale/home`}
              />
              {hasFatturazioneV2(account?.id) && (
                <MenuButton
                  label="FATTURAZIONE"
                  activePage={`${rootConfig.app_path}/fatturazione`}
                  to={`${rootConfig.app_path}/fatturazione/home`}
                />
              )}

              {!!pct_enabled && (
                <>
                  <MenuSeparator />
                  <MenuButton
                    label="REDATTORE"
                    activePage={`${rootConfig.app_path}/redattore`}
                    to={`${rootConfig.app_path}/redattore/depositi`}
                  />
                </>
              )}
            </Box>
            <Box
              id="right"
              direction="row"
              pad="none"
              height="100%"
              justify="end"
              align="end"
              gap="xsmall"
            >
              <MenuSearch />
              <MenuUser
                account={account}
                user={user}
                label="Logout"
                action={actions.logout}
              />
            </Box>
          </>
        )}
        {isCompleta && (
          <>
            <Box
              id="left"
              pad="none"
              direction="row"
              height="100%"
              width="50%"
              justify="start"
              align="end"
            >
              <AppLogo />
            </Box>
          </>
        )}
      </Box>
      <Box
        border={[
          {
            color: 'borderGreen',
            size: 'medium',
            style: 'solid',
            side: 'bottom',
          },
        ]}
      />
    </StyledBox>
  );
};
