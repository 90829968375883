import { debounceTime, from, of } from 'rxjs';
import {
  map,
  withLatestFrom,
  switchMap,
  tap,
  catchError,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import axios from 'axios';
import querystring from 'querystring';
import { LOGIN, LOGOUT, RETRIEVE_AUTH_STATE } from './auth.const';
import { httpMethods, login, request } from '../../../services/http-service';
import { loginSuccess, loginError, logoutEndAction } from './auth.action';
import {
  getItem,
  setItem,
  StorageKeys,
} from '../../../services/storage-service';
import { parseJwt } from '../../../utils/parseJwt';
import { getUserCreditsEpic, getUserDataEpic } from './epics';
import { rootConfig } from '../../../config/root-config';

const loginEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(LOGIN),
    withLatestFrom(state$),
    map(([action]) => action?.payload),
    debounceTime(300),
    tap((payload) => console.log('[login data]', payload)),
    switchMap((data: any) =>
      from(login(data)).pipe(
        tap((payload) => console.log('[login result]', payload)),
        switchMap((response: any) => {
          if (response.error) {
            return of(loginError(response.message || 'error'));
          }

          if (response.result?.session) {
            return of(
              loginError(
                JSON.stringify({
                  mode: 'otp',
                  session: response.result?.session,
                })
              )
            );
          }

          const wpUser = {
            jwt: response?.result?.access_token,
            refresh_token: response?.result?.refresh_token,
            id_token: response?.result?.id_token,
            ...parseJwt(response?.result?.access_token as string),
          };
          delete wpUser.id;

          setItem(StorageKeys.USER, wpUser);

          return from(
            request({
              path: `user/me`,
              method: httpMethods.GET,
            })
          ).pipe(
            map((p: any) => p?.result),
            switchMap((beUser) => {
              const wpUser2 = {
                jwt: response?.result?.access_token,
                refresh_token: response?.result?.refresh_token,
                id_token: response?.result?.id_token,
                ...parseJwt(response?.result?.access_token as string),
              };
              delete wpUser2.id;
              if (!wpUser2?.jwt && !wpUser2?.username) {
                return of(
                  loginError('Si è verificato un errore, si prega di riprovare')
                );
              }
              const user = { ...wpUser2, ...beUser };
              return of(loginSuccess(user));
            })
          );
        }),
        catchError((err) => {
          return of(
            loginError(
              'Si è verificato un errore, si prega di riprovare' || 'error'
            )
          );
        })
      )
    )
  );

const authStateEpic = (action$: any) =>
  action$.pipe(
    ofType(RETRIEVE_AUTH_STATE),
    map(({ payload }) => payload),
    switchMap((jwt: string | null) => {
      const injectedHeaders: any = {};
      if (jwt) {
        injectedHeaders.Authorization = `Bearer ${jwt}`;
      }

      // DO NOT VALIDATE AUTH UNTIL FIRST API
      return of(getItem(StorageKeys.USER)).pipe(
        switchMap((user: any) => {
          if (!user?.jwt && !user?.username) {
            return of(loginError(null));
          }
          return from(
            request({
              path: `user/me`,
              method: httpMethods.GET,
            })
          ).pipe(
            map((p: any) => p?.result),
            switchMap((beUser) => {
              return of(getItem(StorageKeys.USER)).pipe(
                switchMap((newUser: any) => {
                  return of(loginSuccess({ ...newUser, ...beUser }));
                })
              );
            })
          );
        }),
        catchError((err) => {
          console.log('auth state error', err);
          return of(loginError(null));
        })
      );
    })
  );

const logoutEpic = (action$: any) =>
  action$.pipe(
    ofType(LOGOUT),
    switchMap(() => {
      return of(getItem(StorageKeys.USER)).pipe(
        switchMap((user: any) => {
          if (!user?.jwt && !user?.username) {
            return of(logoutEndAction());
          }
          return from(
            axios.post(
              `${rootConfig.sso_endpoint}/logout`,
              querystring.stringify({
                client_id: rootConfig.sso_client_id,
                refresh_token: user?.refresh_token,
              }),
              {
                headers: {
                  Authorization: `Bearer ${user?.jwt}`,
                },
              }
            )
          ).pipe(switchMap(() => of(logoutEndAction())));
        }),
        catchError((err) => {
          console.log('[logout error]', err);
          return of(logoutEndAction());
        })
      );
    })
  );

const epics = [
  loginEpic,
  authStateEpic,
  getUserCreditsEpic,
  getUserDataEpic,
  logoutEpic,
];

export default epics;
