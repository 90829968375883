import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import {
  setLoaders,
  setPopupInfo,
  storeCalendarSchedule,
} from '../../../data.action';
import { RETRIEVE_CALENDAR_SCHEDULE_OUTLOOK } from '../../../data.const';

const retrieveCalendarScheduleOutlookEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_CALENDAR_SCHEDULE_OUTLOOK),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${GET_CALENDAR_SCHEDULE_OUTLOOK}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ calendarsLoader: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/calendar/schedule`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }

              if (p?.result && p?.result[0]?.error) {
                const errors = p.result.filter((r) => !!r.error);
                console.error(
                  `[epic ${RETRIEVE_CALENDAR_SCHEDULE_OUTLOOK}] errors`,
                  errors
                );
              }

              return p?.result.filter((r) => !r.error);
            }),
            tap((p) =>
              console.log(
                `[epic ${RETRIEVE_CALENDAR_SCHEDULE_OUTLOOK}] Retrieved schedule:`,
                p
              )
            ),
            switchMap((data) => of(storeCalendarSchedule(data))),
            catchError((err) => {
              console.error(
                `[epic ${RETRIEVE_CALENDAR_SCHEDULE_OUTLOOK}] error`,
                err
              );
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ calendarsLoader: false }))
      );
    })
  );

export default retrieveCalendarScheduleOutlookEpic;
