import { ofType } from 'redux-observable';
import { delay, map, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import * as querystring from 'querystring';
import { rootConfig } from '../../../../../config/root-config';
import { getProjectsSuccess, GET_PROJECTS, setLoaders } from '../../index';
import { getProjectsList } from '../../../../../services/http/progetti.http-service';

import { noPratiche } from '../../../../../config/customizations';

const epic = (action$: any) =>
  action$.pipe(
    ofType(GET_PROJECTS),
    map(() => `?${querystring.stringify({ page: 1, pageSize: 100 })}`),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        from(getProjectsList(payload)).pipe(
          map((p: any) => ({
            ...p,
            results: null,
            data: [
              {
                id: 'null',
                title: !noPratiche() ? 'Nessuna pratica' : 'Nessun progetto',
              },
              ...(p.results?.map((el: any) => {
                const title = el.descriptions?.[rootConfig.language]; // ?.toLowerCase();
                // title = title.charAt(0).toUpperCase() + title.slice(1);
                return {
                  ...el,
                  title,
                };
              }) || []),
            ],
          })),
          switchMap((data) => of(getProjectsSuccess(data)))
        ),
        of(setLoaders({ documents: false })).pipe(delay(300))
      )
    )
  );

export default epic;
