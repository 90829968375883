import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, iif } from 'rxjs';
import { navigate } from '@reach/router';
import { httpMethods, request } from '../../../../../services/http-service';
import { DELETE_TIMESHEET } from '../../data.const';
import {
  retrieveTimesheetListAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const deleteTimesheetEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DELETE_TIMESHEET),
    map(({ payload: { ids, fase_attivita, isCestino, ...rest } }: any) => ({
      body: {
        ids,
        fase_attivita,
        isCestino,
      },
      listBody: { ...rest, isCestino },
    })),
    tap((p) => console.log(`[epic ${DELETE_TIMESHEET}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, listBody }, _state]) => {
      return concat(
        of(setLoaders({ timesheetLoader: true })),
        defer(() =>
          from(
            request({ path: `timesheet`, method: httpMethods.DELETE, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p;
            }),
            // tap(p => console.log(`[epic ${DELETE_TIMESHEET}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(() =>
              concat(
                iif(
                  () => listBody,
                  of(retrieveTimesheetListAction(listBody)),
                  EMPTY
                ),
                of(null).pipe(
                  tap(() => {
                    switch (body.fase_attivita) {
                      case 'Evento':
                      case 'Attività':
                        // navigate(
                        //   `${rootConfig.app_path}/gestionale/agenda-edit/${
                        //     data?.id || 'crea'
                        //   }`
                        // );
                        navigate(`${rootConfig.app_path}/gestionale/agenda`);
                        break;
                      default:
                        navigate(
                          `${rootConfig.app_path}/gestionale/timesheets`
                        );
                        break;
                    }
                  }),
                  delay(500),
                  switchMap(() =>
                    of(
                      setPopupInfo({
                        opened: true,
                        message: 'Operazione completata con successo',
                        timer: 3000,
                      })
                    )
                  )
                )
              )
            ),
            catchError((err) => {
              console.error(`[epic ${DELETE_TIMESHEET}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message,
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ timesheetLoader: false }))
      );
    })
  );

export default deleteTimesheetEpic;
