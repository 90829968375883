import { ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  ignoreElements,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { concat, defer, EMPTY, from, of } from 'rxjs';
import { navigate } from '@reach/router';
import {
  httpMethods,
  singleFileUpload,
} from '../../../../../services/http-service';
import {
  UPLOAD_DOCUMENTO,
  UPSERT_DOCUMENTO_ONEDRIVE,
  UPSERT_DOCUMENTO_ONEDRIVE_SUCCESS,
} from '../../data.const';
import { setLoaders, setPopupInfo } from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const uploadDocumentoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTO),
    map(({ payload }: any) => payload),
    tap((p) => console.log(`[epic ${UPLOAD_DOCUMENTO}]`, p)),
    withLatestFrom(state$),
    switchMap(([payload, _state]) => {
      const { items, canNavigate } = payload;
      return concat(
        of(setLoaders({ documentoLoader: true })),
        defer(() =>
          concat(
            from(items).pipe(
              concatMap((item: any) =>
                from(
                  singleFileUpload({
                    path: `documento`,
                    method: httpMethods.POST,
                    body: item,
                  })
                ).pipe(
                  map((p: any) => {
                    if (p?.status > 299) {
                      throw new Error(p.message);
                    }
                    return {
                      type: UPSERT_DOCUMENTO_ONEDRIVE,
                      payload: {
                        body: p?.data?.result,
                        canNavigate,
                      },
                    };
                  }),
                  catchError((err) => {
                    console.error(`[epic ${UPLOAD_DOCUMENTO}] error`, err);
                    return of(
                      setPopupInfo({
                        opened: true,
                        message: 'Operazione non riuscita',
                        type: 'BAD',
                        timer: 3000,
                      })
                    );
                  })
                )
              )
            ),
            of(
              setPopupInfo({
                opened: true,
                message:
                  items.length > 1
                    ? 'Documenti caricati'
                    : 'Documento caricato',
                type: 'GOOD',
                timer: 3000,
              })
            )
          )
        ),
        of(setLoaders({ documentoLoader: false }))
      );
    })
  );

export const navigateAfterUploadEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_DOCUMENTO_ONEDRIVE_SUCCESS),
    map(({ payload }: any) => payload),
    tap(() => {
      console.log(
        `[epic ${UPSERT_DOCUMENTO_ONEDRIVE_SUCCESS}] Maybe navigate now`
      );
    }),
    withLatestFrom(state$),
    switchMap(([payload, _state]) => {
      const { canNavigate } = payload;
      return of(null).pipe(
        tap(() => {
          if (canNavigate) {
            navigate(`${rootConfig.app_path}/gestionale/documenti`).then(
              () => null
            );
          }
        })
      );
    }),
    ignoreElements()
  );

export default uploadDocumentoEpic;
